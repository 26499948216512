<template>

 <div id="formulario" class="container col-xxl-8 my-5 p-2" >
    <div class="row align-items-start">
      <div class="pb-5" id="featured-3">
        <div class="g-4 py-2 row-cols-lg-2">
          <div class="me-auto">
          <h2 style="margin-right: 15%;">solicitud<br>de pr&eacute;stamo</h2>
        </div>      
      </div>
    </div>
      <div class="col-md-10 col-sm-8 col-lg-4">
        <img  src="../assets/images/img_char_form.png" class="d-block me-lg-auto img-fluid img-responsive" alt="" loading="lazy">
      </div>
      <div class="field_a col-lg-8  border bg-white" style="border-radius: 15px;">        
        <form class="form row my-3 p-2">
          <div class="ms-3 col-md-12 col-sm-12 col-lg-12 form-check text-start">
            <div class=" col-md-5 col-sm-10 col-lg-8">
              <div class="form-group"> 
                <label style="font-weight: normal;" class="check">Soy mujer y tengo más de 18 años.
                  <input type="checkbox"  name="mayoredad" v-model="mayoredad" >
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>           
          </div>  
          <div class="col-md-4 col-sm-12 col-lg-4">             
            <div class="page">
              <label class="field field_v1">
                <select v-model="estado" v-on:change="GetMunicipios()" class="field__input " aria-label="Default select example" required>
                  <option selected class="bg-option"  disabled>Selecciona una opción</option>
                  <option v-for="edo of estados" :key="edo.EstadoId" class="bg-option" :value="edo.Nombre">{{ edo.Nombre }}</option>
                  
                </select>
                <span class="field__label-wrap">
                  <span class="field__label" style="font-size: 16px;">Estado</span>
                </span>
              </label>              
            </div>
          </div>
         

          <div class="col-md-4 col-sm-12 col-lg-4">             
            <div class="page">
              <label class="field field_v1">
                <select v-model="ciudad"  v-on:change="GetColonias()" class="field__input" aria-label="Default select example" required>
                <option class="bg-option" selected  disabled>Selecciona una opción</option>
                 <option v-for="mpo in municipios" :key="mpo.MunicipioId" class="bg-option" :value="mpo.Nombre">{{ mpo.Nombre }}</option>
             
                </select>
                <span class="field__label-wrap">
                  <span class="field__label" style="font-size: 16px;">Ciudad</span>
                </span>
              </label>              
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-lg-4">             
            <div class="page">
              <label class="field field_v1">
                <select v-model="colonia" class=" field__input" aria-label="Default select example" v-on:change="GetColoniasZonaNP()" required>
                  <option class="bg-option" selected  disabled>Selecciona una opción</option>
                  <option v-for="col in colonias" :key="col.Nombre" class="bg-option" :value="col.Nombre" >{{ col.Nombre }}</option>
                
                </select>
                <span class="field__label-wrap">
                  <span class="field__label" style="font-size: 16px;">Colonia</span>
                </span>
              </label>              
            </div>           
          </div>

           <div class="col-md-4 col-sm-12 col-lg-4 ocultarClass">             
            <div class="page">
              <label class="field field_v1">
                <input v-model="nombre" type="text" class="field__input" placeholder="Nombre" v-on:keyup="this.$data.nombre = NumText(this.$data.nombre)" maxlength="90" required>
                <span class="field__label-wrap">
                  <span class="field__label">Nombre</span>
                </span>
              </label>              
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-lg-4 ocultarClass">             
            <div class="page">
              <label class="field field_v1">
                <input v-model="apellidopaterno" type="text" class="field__input " placeholder="Apellido paterno" v-on:keyup="this.$data.apellidopaterno = NumText(this.$data.apellidopaterno)" maxlength="90" required>
                <span class="field__label-wrap">
                  <span class="field__label">Apellido paterno</span>
                </span>
              </label>              
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-lg-4 ocultarClass">             
            <div class="page">
              <label class="field field_v1">
                <input v-model="apellidomaterno" type="text" class="field__input" placeholder="Apellido materno" v-on:keyup="this.$data.apellidomaterno = NumText(this.$data.apellidomaterno)" maxlength="90" required>
                <span class="field__label-wrap">
                  <span class="field__label">Apellido materno</span>
                </span>
              </label>              
            </div>
          </div>
           <div class="col-md-6 col-sm-12 col-lg-6 ocultarClass">             
            <div class="page">
              <label class="field field_v1">
                <select v-model="estadocivil" class="field__input" aria-label="Default select example" required>
                  <option selected disabled>Selecciona una opción</option>
                  <option >Casada</option>
                  <option >Soltera</option>
                  <option  >Unión libre</option>
                  <option  >Viuda</option>
                  <option  >Divorciada</option>

                </select>
                <span class="field__label-wrap">
                  <span class="field__label" style="font-size: 16px;">Estado civil</span>
                </span>
              </label>              
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-lg-6 ocultarClass">             
            <div class="page">
              <label class="field field_v1">
                <input v-model="fecha" type="date" class="field__input" placeholder="Seleccione una fecha" required>
                <span class="field__label-wrap">
                  <span class="field__label" style="font-size: 16px;">Fecha de nacimiento</span>
                </span>
              </label>              
            </div>
            
          </div>
          <div class="col-md-4 col-sm-12 col-lg-4 ocultarClass">             
            <div class="page">
              <label class="field field_v1">
                <input v-model="celular" type="text" class="field__input " placeholder="Celular" maxlength="10" v-on:keyup="this.$data.celular = Num(this.$data.celular)" required>
                <span class="field__label-wrap">
                  <span class="field__label">Celular</span>
                </span>
              </label>              
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-lg-4 ocultarClass">             
            <div class="page">
              <label class="field field_v1">
                <input v-model="telefono" type="text" class="field__input" placeholder="Teléfono" maxlength="10" v-on:keyup="this.$data.telefono = Num(this.$data.telefono)" required>
                <span class="field__label-wrap">
                  <span class="field__label">Tel&eacute;fono de casa</span>
                </span>
              </label>              
            </div>
          </div>
          <div class="col-md-4 col-sm-12 col-lg-4 ocultarClass">             
            <div class="page">
              <label class="field field_v1">
                <input v-model="email" type="email" class="field__input" placeholder="Correo electrónico" v-on:keyup="this.$data.email = Correo(this.$data.email)" required>
                <span class="field__label-wrap">
                  <span class="field__label">Correo electr&oacute;nico</span>
                </span>
              </label>              
            </div>
          </div>


          <div class="col-md-6 col-sm-12 col-lg-6 ocultarClass">             
            <div class="page">
              <label class="field field_v1">
                <select v-model="clienta" class="field__input" aria-label="Default select example" required>
                  <option class="bg-option" selected disabled>Selecciona una opción</option>
                  <option class="bg-option" value="Si">Sí</option>
                  <option class="bg-option" value="No">No</option>
                </select>
                <span class="field__label-wrap">
                  <span class="field__label" style="font-size: 16px;">¿Has tenido préstamos con nosotros?</span>
                </span>
              </label>              
            </div>
          </div>
          <div v-if="clienta === 'Si'" class="col-md-6 col-sm-12 col-lg-6 ocultarClass">             
            <div class="page">
              <label class="field field_v1">
                <input v-model="ultimocredito" maxlength="6" type="number" min="500" max="50000" class="field__input" placeholder="Cantidad $" required>
                <span class="field__label-wrap">
                  <span class="field__label">Cantidad de su último préstamo</span>
                </span>
              </label>              
            </div>
          </div>
          <div class="col-md-6 col-sm-12 col-lg-6 ocultarClass">             
            <div class="page">
              <label class="field field_v1">
                <select v-model="entero" class="field__input" aria-label="Default select example" required>
                  <option class="bg-option" selected disabled>Selecciona una opción</option>
                  <option class="bg-option" >Internet</option>
                  <option class="bg-option" >Volante</option>
                  <option class="bg-option" >Radio</option>
                  <option class="bg-option" >Papel tortilla</option>
                  <option class="bg-option" >Recomendación</option>
                  <option class="bg-option" >Autobús o estación de Autobús</option>


                </select>
                <span class="my-auto field__label-wrap">
                  <span class="field__label" style="font-size: 16px !important;">¿Cómo me enteré de Credicer?</span>
                </span>
              </label>              
            </div>
          </div>
          <div class="col-md-12 mx-auto  text-center ocultarClass"><p>¿Horarios en que podemos comunicarnos contigo?</p></div>
          <div class=" col-md-5 ocultarClass">             
            <div class="page">
              <label class="field field_v1">
                <select v-model="inicioHora" class="field__input " aria-label="Default select example" v-on:change="GetHorario()">
                  <option class="bg-option" selected disabled>Selecciona una opción</option>
                  <option class="bg-option">08:00 AM</option>
                  <option class="bg-option">09:00 AM</option>
                  <option class="bg-option">10:00 AM</option>
                  <option class="bg-option">11:00 AM</option>
                  <option class="bg-option">12:00 PM</option>
                  <option class="bg-option">01:00 PM</option>
                  <option class="bg-option">02:00 PM</option>
                  <option class="bg-option">03:00 PM</option>
                  <option class="bg-option">04:00 PM</option>
                  <option class="bg-option">05:00 PM</option>
                  <option class="bg-option">06:00 PM</option>
                  <option class="bg-option">07:00 PM</option>
                  <option class="bg-option">08:00 PM</option>
                  <option class="bg-option">09:00 PM</option>
                  <option class="bg-option">10:00 PM</option>
                  <option class="bg-option">11:00 PM</option>                                 
                </select>
               <span class="field__label-wrap">
                  <span class="field__label" style="font-size: 16px;">Inicio</span>
                </span>
              </label>              
            </div>
          </div>
          
          <div class="col-md-1 mx-auto my-auto text-center ocultarClass"><p>a</p></div>
          
           <div class="col-md-5 ocultarClass">             
            <div class="page">
              <label class="field field_v1">
                <select v-model="finHora" class="field__input " aria-label="Default select example" v-on:change="GetHorario()">
                   <option class="bg-option" selected disabled>Selecciona una opción</option>
                  <option class="bg-option">08:00 AM</option>
                  <option class="bg-option">09:00 AM</option>
                  <option class="bg-option">10:00 AM</option>
                  <option class="bg-option">11:00 AM</option>
                  <option class="bg-option">12:00 PM</option>
                  <option class="bg-option">01:00 PM</option>
                  <option class="bg-option">02:00 PM</option>
                  <option class="bg-option">03:00 PM</option>
                  <option class="bg-option">04:00 PM</option>
                  <option class="bg-option">05:00 PM</option>
                  <option class="bg-option">06:00 PM</option>
                  <option class="bg-option">07:00 PM</option>
                  <option class="bg-option">08:00 PM</option>
                  <option class="bg-option">09:00 PM</option>
                  <option class="bg-option">10:00 PM</option>
                  <option class="bg-option">11:00 PM</option>                
                </select>
              <span class="field__label-wrap">
                  <span class="field__label" style="font-size: 16px;">Fin</span>
                </span>
              </label>              
            </div>
          </div>
          <div class="my-2 col-md-12 colsm-12 col-lg-12 text-center ocultarClass">
            <h4 style="font-size: 16px;">Seleccione el monto que desea obtener:</h4>
          </div>
          <div class="col-md-12 col-sm-12 col-lg-12 ocultarClass">
            <div class="mx-auto my-1 col-md-10 col-sm-10 col-lg-10">
                <div class="slidecontainer text-center" id="s1">
                  <input v-model="credito" type="range" min="1000" step="1000" max="15000"  class="slider" id="myRange" data-toggle="tooltip" data-placement="top" title="Seleccione el monto del crédito">
                  <p style="margin: 10px; font-size: 23px; color: #787878;">Préstamo: $<span id="demo">{{ credito }}</span>.00</p> 
               </div>
          </div>
          </div>
          
          <div class="col-md-8 col-sm-12 col-lg-8 form-check ocultarClass">
            <div class=" col-md-10 mx-auto text-start">
              <div class="form-group"> 
                <label style="font-weight: normal;" class="check">He leído y acepto el <a href="/Terminos/privacidadcandidatos.html" target="_blank">Aviso de Privacidad</a> puesto a mi disposición
                  <input type="checkbox"  name="acepto" v-model="acepto" >
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>           
          </div>  
          <input type="text" v-model="horario" style="display: none;"/>
          <button id="btnEnviarFormulario" type="button" class=" mx-auto my-2 btn col-md-3 col-sm-12 col-lg-4" style="background-color: #D33680; color:azure;border-radius: 11px;" @click="ValidationForm()">Solicitar préstamo </button>
          
        </form>

          <button style="display: none;" id="modal_plan" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#myModal">
            modal val
          </button>
          <button style="display: none;" id="modalTabla" type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalTablaE">
            modal tbl
          </button>


      </div>
    </div>
  </div>

 
<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade text-start" id="myModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Requisitos</h5>
        <button id="cerrarRequisitos" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         <p style="margin-bottom: 0px;">
                Para realizar la solicitud de crédito debe contar con los siguientes requisitos: </p>         

            <ul style="color: #959094;">
                <li style="border-bottom: 0px;">Ser mujer mayor de edad</li>
                <li style="border-bottom: 0px;">Copia de INE (solicitante y aval)</li>
                <li style="border-bottom: 0px;">Copia de CURP (solicitante y aval)</li>
                <li style="border-bottom: 0px;">Comprobante de domicilio vigente no mayor a tres meses (Solicitante y Aval)</li>
                <li style="border-bottom: 0px;">Aval</li>
                <li style="border-bottom: 0px;">Enunciar tres garantías prendarias (tv, microondas, teatro en casa, estéreos, etc.)</li>
            </ul>            
            <p style="margin-bottom: 0px;">Uno de nuestros asesores se pondrá en contacto con usted en un lapso de 24 a 48 hrs. para ubicarle al punto de venta más cercano a su colonia: </p>
            <br>
            <p style="margin-bottom: 0px;">
                <strong>Aviso:</strong><br>
                Cuando la fecha límite de pago corresponda a un día inhábil bancario, el pago podrá realizarse sin cargo.
            </p>
            <ul style="color: #959094;">
                <li style="border-bottom: 0px;">Contratar créditos por arriba de tu capacidad de pago puede afectar tu historial crediticio</li>
                <li style="border-bottom: 0px;">Incumplir tus obligaciones te puede generar comisiones e intereses moratorios</li>
                <li style="border-bottom: 0px;">El avalista, obligado solidario o coacreditado responderá como obligación principal por el total del pago frente a la Entidad Financiera</li>
            </ul>
            <p class="text-center" style="margin-bottom: 0px; margin-top: 15px;">¿Desea continuar con su proceso de solicitud?</p>
      </div>
      <div class="modal-footer">
        <button id="cancelarEnvio" type="button" class="btn btn-danger" data-bs-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-success" @click="EnvioCorreo()">Aceptar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade text-start" id="modalTablaE" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Tabla de amortización</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        
        <table id="tblAmort" class="table">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Fecha</th>
              <th scope="col">N.P.</th>
              <th scope="col">M. a pagar</th>
              <th scope="col">Capital</th>
              <th scope="col">Interés</th>
              <th scope="col">IVA</th>
              <th scope="col">Saldo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in tblAmortizacion" :key="row.nUMPago">
              <td> {{  new Date(row.fecha).toLocaleString([], {dateStyle: 'short'}) }} </td>
              <td> {{row.nUMPago}}</td>
              <td>$ {{row.totalAPagar}}</td>
              <td>$ {{row.capital}}</td>
              <td>$ {{row.interes}}</td>
              <td>$ {{row.iva}}</td>
              <td>$ {{row.saldo}}</td>
            </tr>            
          </tbody>
        </table>

        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-bs-dismiss="modal">Aceptar</button>
      </div>
    </div>
  </div>
</div>
  
</template>
<script>
//import {Solicitud} from '@/js/solicitud.js'
import axios from "axios";
import { Positions } from '@meforma/vue-toaster'

 
const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $

export default ({
    name: 'FormularioSolicitud',
    
    data(){
    return {
        mayoredad:false,
        mayorEdad:0,
        estado:"",
        ciudad:"",
        colonia:"",
        nombre:"",
        apellidopaterno:"",
        apellidomaterno:"",
        estadocivil:"",
        fecha:"",
        celular:"",
        telefono:"",
        email:"",
        clienta:"",
        ultimocredito:"",
        entero:"",
        inicioHora:"",
        finHora:"",
        credito:1000,
        acepto:false,
        prueba:"",
        estados:null,
        municipios:null,
        colonias:null,
        getNombreEstado:"",
        getNombreMunicipio:"",
        horario:"",
        correosave:null,
        message: "",
        options: {
          duration: 5000,
          position:"bottom"
        },
        uri: "https://credicer.mx/api",
        zonaNP:"",
        validation:false,
        tblAmortizacion: null,
        bandera: true
    }},
    computed:{
        positions() {
        return Object.keys(Positions).map((key) => {
          return {
            key,
            value: Positions[key],
            name: Positions[key].replace(/-/, ' ')
          }
        })
      },
      hasOptions() {
        return (
          this.options &&
          (Object.keys(this.options).length > 1 || this.options.duration !== 4000)
        )
      }
    },
    mounted(){
       this.GetEstados();
    },
    methods: {
        mostrarCampos(){
              
           /*if(this.mayoredad===true){
              this.mayorEdad = 1;
           }
           if(this.acepto===true){
              this.acepto = 1;
           }*/
            var solicitud = {
              mayoredad: this.mayoredad,
              estado:	this.estado	,
              ciudad:	this.ciudad	,
              colonia:	this.colonia	,
              estadocivil:	this.estadocivil	,
              nombre:	this.nombre	,
              apellidopaterno:	this.apellidopaterno	,
              apellidomaterno:	this.apellidomaterno	,              
              fecha:	this.fecha	,
              celular:	this.celular	,
              telefono:	this.telefono	,
              email:	this.email	,
              entero:	this.entero	,
              acepto:	this.acepto	,
              horario: this.horario,
              credito:	this.credito,
              clienta:	this.clienta	,
              ultimocredito: this.ultimocredito  
            };
            alert(JSON.stringify(solicitud, null, 5))
          
        },
        GetEstados(){
          this.colonias = null;
          axios
          .get(this.uri+'/estado')
          .then(response => {
            if(response.status == 200){
              this.estados = response.data.estado;
              
            }
            
            console.log(this.estados);
            console.log(response.status);
            console.log(this.estados.length);
            
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.isLoading = false)
         
        },
        GetMunicipios(){
          for(var i =0; i < this.estados.length; i++){
                  console.log('entra en for estados');
                //console.log('Estado en mostrar: '+ this.estado)
                if(this.estados[i].Nombre == this.estado){
                    this.getNombreEstado = this.estados[i].EstadoId;
                    //console.log('BK: '+this.getNombreEstado);
                }                
          }
          this.GetEstadosCobertura();
         // console.log('Get nombre: '+ this.getNombreEstado);
          this.municipios = null;
          this.colonias = null;
          axios
          .get(this.uri+'/municipio/'+this.getNombreEstado)
          .then(response => {
            this.municipios = response.data.municipio
            //console.log(this.municipios);
            //console.log(this.municipios.length);
            
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.isLoading = false)
         
        },
         GetColonias(){
           for(var j =0; j < this.municipios.length; j++){
                //console.log('Estado en mostrar: '+ this.estado)
                if(this.municipios[j].Nombre === this.ciudad){
                    this.getNombreMunicipio = this.municipios[j].MunicipioId;
                    //console.log(this.getNombreMunicipio);
                }
                
            }
            this.GetMunicipiosCobertura();
            
          axios
          .get(this.uri+'/colonia/'+this.getNombreEstado+'/'+this.getNombreMunicipio)
          .then(response => {
            this.colonias = response.data.colonia
            console.log(this.colonias);            
            
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.isLoading = false)
         
        },
        GetHorario(){
          var hor = this.inicioHora + ' a '+ this.finHora;
          this.horario = hor;
        },
        GetTablaAmortizacion(){
          console.log(this.credito);

          axios
          .get(this.uri+'/tablaamortizacion?prod=14&&cred='+this.credito)
          .then(response => {
            if(response.status == 200){
              this.sortTable();
              this.tblAmortizacion = response.data.tb.response.data;
              $("#cerrarRequisitos").trigger("click");
              this.tblAmortizacion.sort(function (a, b){
                  return (a.nUMPago - b.nUMPago)
              })

                        let date1 = new Date();                        
                        let date3 = new Date();
                        let date_first = '';

                        let weekFirstDay1 = new Date(date1.setDate(date1.getDate() - (date1.getDay()-1)));
                        date1.setHours( 0,0,0,0 );
                        console.log(weekFirstDay1);
                        let cobro1 =  new Date(date1.setDate(date1.getDate() + 4));
                

                        console.log(cobro1);

                        if(date3 >= cobro1){
                            let endDate2 = new Date();
                            //console.log('now TEMP = ', endDate2);
                            endDate2.setDate(endDate2.getDate() + 3);

                            let weekFirstDay1 = new Date(endDate2.setDate(endDate2.getDate() - (endDate2.getDay()-1)));
                            console.log(weekFirstDay1);
                            endDate2.setHours( 23,59,59,0 );
                            let cobro4 =  new Date(endDate2.setDate(endDate2.getDate() + 4));

                            date_first = cobro4;
                            console.log('test + ', date_first);
                        }else{
                            date_first = cobro1;
                            console.log('test2 + ', date_first);
                        }

                        

                        for (var i in this.tblAmortizacion) {
                            let endDateCount = new Date(date_first);
                            if(i == 0){
                                this.tblAmortizacion[i].fecha = endDateCount;
                            }else{
                                date_first.setDate(date_first.getDate() + 7);
                            
                                let endDateCount1 = new Date(date_first);
                                
                                this.tblAmortizacion[i].fecha = endDateCount1;
                            }
                        }



               console.log('aqui')
               
              console.log(response.data.tb.response.data)
            }            
            
            
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.isLoading = false)
          this.credito=1000;
        },
        ValidationForm(){
           this.validation = this.ValidarSolicitud(this.mayoredad,
                                                this.estado,
                                                this.ciudad,
                                                this.colonia,
                                                this.estadocivil,
                                                this.nombre,
                                                this.apellidopaterno,
                                                this.fecha,
                                                this.celular,
                                                this.telefono,
                                                this.email,
                                                this.entero,
                                                this.acepto,
                                                this.horario,
                                                this.credito,
                                                this.clienta,
                                                this.ultimocredito);
          if(this.validation !== false){
            $("#modal_plan").trigger("click");
          }
        },
        EnvioCorreo(){
          
          if(this.validation !== false){
                //formato del formdata
            var formData = new FormData();
            formData.append('mayoredad', this.mayoredad);
            formData.append('estado', this.estado);
            formData.append('ciudad', this.ciudad);
            formData.append('colonia', this.colonia);
            formData.append('estadocivil', this.estadocivil);
            formData.append('nombre', this.nombre);
            formData.append('apellidopaterno', this.apellidopaterno);
            formData.append('apellidomaterno', this.apellidomaterno);
            formData.append('fecha', this.fecha);
            formData.append('celular', this.celular);
            formData.append('telefono', this.telefono);
            formData.append('email', this.email);
            formData.append('entero', this.entero);
            formData.append('acepto', this.acepto);
            formData.append('horario', this.horario);
            formData.append('credito', this.credito);
            formData.append('clienta', this.clienta);
            formData.append('ultimocredito', this.ultimocredito);

            console.log('Entra a enviar correo');

            //peticion por axios
            axios(            
                { 
                  method:"post",
                  url:this.uri+'/correosave',
                  data: formData,
                  //pasamos los encabezados del post request 
                  headers: {
                  'content-type': 'application/x-www-form-urlencoded'
                  }
                })
            .then(response => {
              if(response.status == 200){
                this.correosave = response.data.entidad
                console.log(response.data);
                console.log(this.correosave);
                console.log(this.correosave.length);
                this.GetTablaAmortizacion();
                this.sortTable();
                this.message = "Aviso: ¡Gracias por comunicarte por este medio, uno de nuestros asesores se comunicará con uds!";

                this.toast('success');
                
                $("#cerrarRequisitos").trigger("click");
                $("#cancelarEnvio").trigger("click");
                $("#modalTabla").trigger("click");
                
              }
              
              
            })
            .catch(error => {
              console.log(error)
              this.errored = true
            })
            .finally(() => this.isLoading = false);

              this.mayoredad=false;
              this.mayorEdad=0;
              this.estado="";
              this.ciudad="";
              this.colonia="";
              this.nombre="";
              this.apellidopaterno="";
              this.apellidomaterno="";
              this.estadocivil="";
              this.fecha="";
              this.celular="";
              this.telefono="";
              this.email="";
              this.clienta="";
              this.ultimocredito="";
              this.entero="";
              this.inicioHora="";
              this.finHora="";
              
              this.acepto=false;
              this.municipios=null;
              this.colonias=null;
              this.getNombreEstado="";
              this.getNombreMunicipio="";
              this.horario="";
              this.correosave=null;
              this.message= "";
         }else{
           location.href = "#formulario";
         }
          
         
        },
        ValidarSolicitud(mayoredad,estado,ciudad,colonia,estadocivil,nombre,apellidopaterno,fecha,celular,telefono,email,entero,acepto,horario,credito,clienta,ultimocredito){
            
            if(mayoredad == false){
                this.message = "¡Debes seleccionar si eres mayor de edad!";
                this.toast('warning');
                return false;
            }
            if(estado === "" || estado == null || estado == undefined){
              this.message = "¡Debes seleccionar un Estado!";
              this.toast('warning');
              return false;
            }
            if(ciudad == ""){
              this.message = "¡Debes seleccionar una Ciudad!";
              this.toast('warning');
              return false;
            }
            if(colonia == ""){
              this.message = "¡Debes seleccionar una Colonia!";
              this.toast('warning');
              return false;
            }
            if(estadocivil == ""){
              this.message = "¡Debes seleccionar un Estado Civil!";
              this.toast('warning');
              return false;
            }
            if(nombre == ""){
              this.message = "¡Debes ingresar un nombre!"
              this.toast('warning');
              return false;
            }
            if( apellidopaterno == ""){
              this.message = "¡Debes ingresar el primer apellido!";
              this.toast('warning');
              return false;
            }
            console.log('fecha: '+fecha);
            if(fecha == ""){
              this.message = "¡Debes seleccionar una fecha de nacimiento!";
              this.toast('warning');
              return false;
            }
            if(celular == ""){
              this.message = "¡Debes ingresar un número de celular!";
              this.toast('warning');
              return false;
            }
            if(telefono == ""){
              this.message = "¡Debes ingresar un número de teléfono!";
              this.toast('warning');
              return false;
            }
            if(email == ""){
              this.message = "¡Debes ingresar un correo electrónico!";
              this.toast('warning');
              return false;
            }
            if(entero == ""){
              this.message = "¡Debes seleccionar cómo te enteraste de credicer!";
              this.toast('warning');
              return false;
            }
            if(acepto == false){
              this.message = "¡Debes aceptar los términos y condiciones!";
              this.toast('warning');
              return false;
            }
            if (horario == "" || this.finHora == "" || this.inicioHora == ""){
              this.message = "¡Debes seleccionar un horario válido!";
              this.toast('warning');
              return false;
            }
            if (credito == ""){
              this.message = "¡Debes ingresar un monto!";
              this.toast('warning');
              return false;
            }
            if(clienta == "Si" && ultimocredito == ""){
              
                this.message = "¡Debes ingresar el monto de tu último crédito!";
                this.toast('warning');
                return false;
              
            }
            if(clienta == ""){
                this.message = "¡Debes seleccionar si has tenido créditos con nosotros!";
                this.toast('warning');
                return false;
            }
            

        },
        toast(type = 'default', dismissible = true) {
          const options = {
            dismissible,
            onClick: this.onClick
          }
          typeof type === 'string' && (options.type = type)

          typeof this.options.maxToasts === 'string' &&
            (this.options.maxToasts = parseInt(this.options.maxToasts))

          this.$toast.show(this.message, {
            ...options,
            ...this.options
          })
        },
        changeDuration(e) {
          this.options.duration = !e.target.checked ? 4000 : false
        },
        onClick(e) {
          console.log(e)
        },
        Num(string){//solo letras y numeros

            var out = '';

            //Se añaden las letras validas

            var filtro = '1234567890';//Caracteres validos
            for (var i=0; i<string.length; i++)

               if (filtro.indexOf(string.charAt(i)) != -1) 

                 out += string.charAt(i);
                 console.log(out);
                 return out;

        },
        Correo(string){//solo letras y numeros
            var out = '';
            //Se añaden las letras validas
            var filtro = 'abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ123456789@_-.';//Caracteres validos
            for (var i=0; i<string.length; i++)

               if (filtro.indexOf(string.charAt(i)) != -1) 
                 out += string.charAt(i);
            return out;
        },
        NumText(string){//solo letras y numeros
            var out = '';
            var filtro = 'abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ ';//Caracteres validos
            for (var i=0; i<string.length; i++)
                if (filtro.indexOf(string.charAt(i)) != -1) 
                out += string.charAt(i).toUpperCase();
            return out;
        },
        GetColoniasZonaNP(){ 
           for(var j =0; j < this.colonias.length; j++){
                //console.log('Estado en mostrar: '+ this.estado)
                if(this.colonias[j].Nombre === this.colonia){
                    this.zonaNP = this.colonias[j].zonaNP;
                }
                
                
            }
            if(this.zonaNP==="1"){
                  this.message = "Aviso: ¡Lo sentimos, por el momento no contamos con cobertura en tu zona, agradecemos tu preferencia CREDICER micro préstamos para la mujer!";
                  this.toast('info');
                  document.getElementsByClassName('ocultarClass')[0].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[1].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[2].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[3].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[4].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[5].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[6].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[7].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[8].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[9].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[10].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[11].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[12].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[13].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[14].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[15].style.display = "none";

                  document.getElementById('btnEnviarFormulario').disabled = true;
            }else
            {
                document.getElementsByClassName('ocultarClass')[0].style.display = "block";
                document.getElementsByClassName('ocultarClass')[1].style.display = "block";
                document.getElementsByClassName('ocultarClass')[2].style.display = "block";
                document.getElementsByClassName('ocultarClass')[3].style.display = "block";
                document.getElementsByClassName('ocultarClass')[4].style.display = "block";
                document.getElementsByClassName('ocultarClass')[5].style.display = "block";
                document.getElementsByClassName('ocultarClass')[6].style.display = "block";
                document.getElementsByClassName('ocultarClass')[7].style.display = "block";
                document.getElementsByClassName('ocultarClass')[8].style.display = "block";
                document.getElementsByClassName('ocultarClass')[9].style.display = "block";
                document.getElementsByClassName('ocultarClass')[10].style.display = "block";
                document.getElementsByClassName('ocultarClass')[11].style.display = "block";
                document.getElementsByClassName('ocultarClass')[12].style.display = "block";
                document.getElementsByClassName('ocultarClass')[13].style.display = "block";
                document.getElementsByClassName('ocultarClass')[14].style.display = "block";
                document.getElementsByClassName('ocultarClass')[15].style.display = "block";
                
                document.getElementById('btnEnviarFormulario').disabled = false;
            }

         },
        ShowModal(){
           $('#myModal').modal('show');
        },
        sortTable() {
        var table, rows, switching, i, x, y, shouldSwitch;
        table = document.getElementById("tblAmort");
        switching = true;
        /*Make a loop that will continue until
        no switching has been done:*/
        while (switching) {
          //start by saying: no switching is done:
          switching = false;
          rows = table.rows;
          /*Loop through all table rows (except the
          first, which contains table headers):*/
          for (i = 1; i < (rows.length - 1); i++) {
            //start by saying there should be no switching:
            shouldSwitch = false;
            /*Get the two elements you want to compare,
            one from current row and one from the next:*/
            x = rows[i].getElementsByTagName("TD")[1];
            y = rows[i + 1].getElementsByTagName("TD")[1];
            //check if the two rows should switch place:
            if (Number(x.innerHTML) > Number(y.innerHTML)) {
              //if so, mark as a switch and break the loop:
              shouldSwitch = true;
              break;
            }
          }
          if (shouldSwitch) {
            /*If a switch has been marked, make the switch
            and mark that a switch has been done:*/
            rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
            switching = true;
          }
        }
      },
      GetEstadosCobertura(){         
          //console.log('Get nombre: '+ this.getNombreEstado);
          this.estadosCobertura = null;
          this.municipioCobertura = null;
          axios
          .get(this.uri+'/estadoCobertura/'+this.getNombreEstado)
          .then(response => {
            this.estadosCobertura = response.data.estado
            console.log(this.estadosCobertura);
            //console.log(this.municipios.length);
            if(this.estadosCobertura.length==1){
            for(var i =0; i < this.estadosCobertura.length; i++){
                if(this.estadosCobertura[i].nPlaza == 0){
                  this.message = "¡Lo sentimos, por el momento no contamos con cobertura en tu zona, agradecemos tu preferencia CREDICER micro préstamos para la mujer!";
                  this.toast('info');
                  document.getElementsByClassName('ocultarClass')[0].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[1].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[2].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[3].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[4].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[5].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[6].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[7].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[8].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[9].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[10].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[11].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[12].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[13].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[14].style.display = "none";
                  document.getElementsByClassName('ocultarClass')[15].style.display = "none";

                  document.getElementById('btnEnviarFormulario').disabled = true;
                  this.bandera=false;
                  break;
                }else
                {
                    document.getElementsByClassName('ocultarClass')[0].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[1].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[2].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[3].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[4].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[5].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[6].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[7].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[8].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[9].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[10].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[11].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[12].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[13].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[14].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[15].style.display = "block";
                    
                    document.getElementById('btnEnviarFormulario').disabled = false;
                    this.bandera=true;

                }                
            }
          }
          else
                {
                    document.getElementsByClassName('ocultarClass')[0].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[1].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[2].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[3].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[4].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[5].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[6].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[7].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[8].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[9].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[10].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[11].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[12].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[13].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[14].style.display = "block";
                    document.getElementsByClassName('ocultarClass')[15].style.display = "block";
                    
                    document.getElementById('btnEnviarFormulario').disabled = false;
                    this.bandera=true;

                }     
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.isLoading = false);
          
        },
        GetMunicipiosCobertura(){         
          //console.log('Get nombre: '+ this.getNombreEstado);
          
          axios
          .get(this.uri+'/municipioCobertura/'+this.getNombreEstado)
          .then(response => {
            this.municipioCobertura = response.data.municipio
           // console.log(this.municipios);
            //console.log(this.municipios.length);
            for(var i =0; i < this.municipioCobertura.length; i++){
                  if(this.municipioCobertura[i].idMunicipio == this.getNombreMunicipio){ 
                    console.log(this.municipioCobertura[i].idMunicipio);
                    if(this.municipioCobertura[i].nPlaza == 0){
                      this.municipioCobertura = null;
                      this.message = "¡Lo sentimos, por el momento no contamos con cobertura en tu zona, agradecemos tu preferencia CREDICER micro préstamos para la mujer!";
                      this.toast('info');
                      document.getElementsByClassName('ocultarClass')[0].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[1].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[2].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[3].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[4].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[5].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[6].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[7].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[8].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[9].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[10].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[11].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[12].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[13].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[14].style.display = "none";
                      document.getElementsByClassName('ocultarClass')[15].style.display = "none";

                      document.getElementById('btnEnviarFormulario').disabled = true;
                      this.bandera=false;
                      
                      break;
                    } else
                    {
                        document.getElementsByClassName('ocultarClass')[0].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[1].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[2].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[3].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[4].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[5].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[6].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[7].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[8].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[9].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[10].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[11].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[12].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[13].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[14].style.display = "block";
                        document.getElementsByClassName('ocultarClass')[15].style.display = "block";
                        
                        document.getElementById('btnEnviarFormulario').disabled = false;
                        this.bandera=true;

                    }     
                  }                
            }
          })
          .catch(error => {
            console.log(error)
            this.errored = true
          })
          .finally(() => this.isLoading = false);
           
           
          
        },
        
    }
})
</script>
<style scoped>
button.error {
  background-color: rgba(241, 34, 34, 0.68);
  border-color: #f12222;
}
</style>
