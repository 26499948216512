import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap";
import bootstrap from 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import 'vue-loading-overlay/dist/vue-loading.css';
import "../src/assets/css/formulario.css";
import "../src/assets/css/general.css";
import Toaster from "@meforma/vue-toaster";


const app = createApp(App);
app.use(router);
app.use(Toaster);
app.use(bootstrap);

app.mount('#app');

 