<template>
<nav class="shadow navbar navbar-expand-lg a-nav bg-white fixed-top" aria-label=" ">
    <div class="container-xl">
       <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent5" aria-controls="navbarSupportedContent5" aria-expanded="false" aria-label="Toggle navigation">
      <span class=""><h3 ><i class="bi bi-list"></i></h3>
    </span>
    </button>

      <div class="row collapse navbar-collapse ms-5" id="navbarSupportedContent5">
        <ul class="navbar-nav mb-2">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#featured-4">Quienés Somos</a>
          </li>        
          <li class="nav-item mx-5">
            <a class="nav-link active" aria-current="page" href="#featured-5">Productos</a>
          </li>  
          <li class="nav-item mx-5">
            <router-link class="nav-link active" to="/EducacionFinanciera" aria-current="page">Educación Financiera</router-link>
            <!--<a class="nav-link active" aria-current="page" href="/EducacionFinanciera"></a>-->
          </li>
          <li class="nav-item dropdown mx-5">
            <a class="nav-link dropdown-toggle" href="#" id="dropdown07XL" data-bs-toggle="dropdown" aria-expanded="false">Clientas</a>
            <ul class="dropdown-menu" aria-labelledby="dropdown07XL">
              <li><a class="dropdown-item" href="#estadoCuenta">Estado de cuenta</a></li>
              <li><a class="dropdown-item" href="#formulario-soli">Solicitar</a></li>
            </ul>
          </li>
          <li class="nav-item mx-5">
            <a class="nav-link active" aria-current="page" href="#contacto">Contacto</a>
          </li>
        </ul>
      </div>
      
    </div>
  </nav>
  <router-view/>
  <FlashMessage></FlashMessage>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script>

</script>